import React from 'react';
import Link from 'next/link'

import { event } from '../../lib/gtag';

import SocialMedia from '../SocialMedia';
import Newsletter from '../Newsletter';
import SubMenuItems from '../SubMenuItems';

import { 
  vehicleItems, qaVehicleItems,
  helpItems, qaHelpItems,
  toolsItems, qaToolsItems,
  companyItems, qaCompanyItems,
  servicesItems, qaServicesItems
} from '../SubMenuItems/menuItems'

import styles from './styles.module.scss';
import { copyrightDisplayText } from "../../helper/functions/copyrightDisplayText";


const Footer = () => {
  const [VehicleItems]: any = SubMenuItems({ items: vehicleItems, qaItems: qaVehicleItems, eventType: 'Footer' });
  const [LearnItems]: any = SubMenuItems({ items: helpItems, qaItems: qaHelpItems, eventType: 'Footer' });
  const [ToolsItems]: any = SubMenuItems({ items: toolsItems, qaItems: qaToolsItems, eventType: 'Footer' });
  const [ServicesItems]: any = SubMenuItems({ items: servicesItems, qaItems: qaServicesItems, eventType: 'Footer' });
  const [CompanyItems]: any = SubMenuItems({ items: companyItems, qaItems: qaCompanyItems, eventType: 'Footer' });

  return (
    <div className={styles.footer}>
      <div className={styles.footerContainer}>
        
        <div className={styles.footerNavsHolder}>
          <div className={styles.footerNavColumn}>
            <h1 className={styles.footerleadingNav} onClick={() => event("vehiclesFooter")}>
              <Link href="/vehicles/all">Browse Vehicles</Link>
            </h1>
            
            <div>
              { VehicleItems }
            </div>
          </div>

          <div className={styles.footerNavColumn}>
            <h1 className={styles.footerleadingNav} onClick={() => event("helpFooter")}>
              <Link href="/learn/articles">Help & Advice</Link>
            </h1>
            
            <div>
              { LearnItems }
            </div>
          </div>

          <div className={styles.footerNavColumn}>
            <h1 className={`${styles.footerleadingNav} default-cursor`}>
              Tools
            </h1>
            
            <div>
              { ToolsItems }
            </div>
          </div>

          <div className={styles.footerNavColumn}>
            <h1 className={`${styles.footerleadingNav} default-cursor`}>
              Services
            </h1>

            <div>
              { ServicesItems }
            </div>
          </div>

          <div className={styles.footerNavColumn}>
            <h1 className={styles.footerleadingNav + " " + styles.companyNav}>Company</h1>
            
            <div>
              { CompanyItems }
            </div>
          </div>

          <div className={styles.footerNavColumn}>
            <h1 className={styles.footerleadingNav + " " + styles.nevoChargingNav}>
              <a onClick={() => event("nevoAdvisoryFooter")} href='https://nevoadvisory.ie' target='_blank' rel='noreferrer'>
                Nevo Advisory
              </a>
            </h1>
          </div>
        </div>

        <div className={styles.newsletterAndFollow}>
          <div className={styles.newsletterHolder}>
            <Newsletter whiteVersion />
          </div>

          <div className={styles.followHolder}>
            <SocialMedia whiteVersion={true} />
          </div>
        </div>

        <div className={styles.copyrightHolder}>
          <p className="copyright-mark">{copyrightDisplayText()}</p>
        </div>
      </div>
    </div>
  )
}

export default Footer;