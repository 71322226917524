import { createContext, useEffect, useState } from "react";
import { useRouter } from "next/router";

export const TransitionContext = createContext<any>(null)

export default function TransitionStore({ children }: any) {
  const [isTransitioning, setIsTransitioning] = useState(true);
  const [deboucnedIsTransitioning, setDebounced] = useState(true); 

  const router = useRouter();

  useEffect(() => {
    if (router.isReady) {
      const changeStart = () => {
        setIsTransitioning(true);

        // destroy all ad slots (for next.js)
        const { googletag } = window;
        googletag?.cmd?.push(() => {
          googletag.destroySlots();
        });
      }
      const changeComplete = () => {
        setIsTransitioning(false);
      }
      
      router.events.on("routeChangeStart", changeStart)
      router.events.on("routeChangeComplete", changeComplete)

      return () => {
        router.events.off("routeChangeStart", changeStart)
        router.events.off("routeChangeComplete", changeComplete)
      }
    }
  }, [router])

  // deboucing isTransitioning for Search page (1 second)
  useEffect(() => {
    if (isTransitioning) {
      setDebounced(true);
    } 
    else {
      let timeout = setTimeout(() => {
        setDebounced(false);
      }, 1000)

      return () => clearTimeout(timeout);
    }
  }, [isTransitioning])

  return (
    <TransitionContext.Provider value={{isTransitioning, deboucnedIsTransitioning}}>
      { children }
    </TransitionContext.Provider>
  )
}