
import React, { useState, useRef } from 'react';
import useMobile from '../Hooks/Mobile';

import axios, { Canceler } from 'axios';
import { toast } from 'react-toastify';
import Backend from '../../utils/Backend';
import { emailRegex } from '../../helper/constants';

import Spinner from '../Spinner';

import styles from './styles.module.scss';

import { event } from '../../lib/gtag';
import useCancel from '../Hooks/CancelFetch';

interface NewsletterProps {
  whiteVersion?: boolean;
  noHeader?: boolean;
  big?: boolean;
  className?: string;
}

export default function Newsletter({ whiteVersion, noHeader, big, className } : NewsletterProps) {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("Thanks! You'll hear from us soon.");
  const emailHolderRef = useRef<HTMLDivElement>(null);

  const cancelRef = useRef<Canceler>();

  const smallDesktop = useMobile(1440)

  const submissionAnimation = (firstTime = false) => {
    if (emailHolderRef && emailHolderRef.current) {
      emailHolderRef.current.classList.toggle(styles.hideEmail)
    }

    setTimeout(() => {
      setSubmitted(true);

      if (firstTime) {
        window.localStorage.setItem("isSubscribed", "yes");
      }
    }, 500)
  } 

  useCancel(cancelRef.current);

  const submitEmail = () => {
    if (!emailRegex.test(email)) {
      toast.error("Please, provide correct e-mail address") 
      return
    }
    setLoading(true);

    event("subscribedToNewsletter")
    
    const { promise, cancel } = Backend.subscribeToNewsletter(email)
    
    cancelRef.current = cancel;

    promise.then((res) => {
      if (res.status === 201) submissionAnimation(true)
    })
    .catch((err) => {
      if (axios.isCancel(err)) return;

      if (err.response.status === 400) {
        let message: string = err.response.data.message;
        
        if (message.includes("The email address provided already exists.")) {
          message = "You're already subscribed to our newsletter."
        }

        setMessage(message);
        submissionAnimation();
      }
      else {
        toast.error("Error occurred! Currently, we're experiencing technical issues, but we're working on that. Please, try again soon!");
        setLoading(false);
      }
    }); 
  }
  

  return (
    <div className={`${styles.newsletter} ${whiteVersion && styles.whiteVersion} ${className} ${(big && !smallDesktop) && styles.big}`}>
      { !noHeader && <h1 className="nav-button">Newsletter</h1> }
      
      <div className={`${styles.emailHolder} ${noHeader && styles.noHeader}`} ref={emailHolderRef}>
        { !submitted && 
          <>
            <input 
              placeholder="Your email"
              type="email"
              value={email}
              onChange={({target}) => setEmail(target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && !loading && !submitted) {
                  submitEmail();
                }
              }}
            /> 
            <div className={`${styles.buttonHolder} button-holder`}>
              { loading ? 
                <div className={`${styles.spinnerHolder} spinner-holder`}><Spinner /></div> :
                <button className="blue-button" onClick={submitEmail}>Subscribe</button>
              }
            </div>
          </>
        } 

        { submitted && 
          <p>{ message }</p> 
        }
      </div>
    </div>
  )
}
