
export const formatNumber = (number: number) => {
  if (number === 0) return "0"; 

  let string = `${Math.round(number)}`;

  const array = string.split("").reverse()

  string = ""

  // add space every 3 digits
  for (let position = 0; position < array.length; position += 3) {
    string += array.slice(position, position + 3).join("") + ","
  }
  string = string.slice(0, string.length - 1) // remove last symbol

  string = string.split("").reverse().join("").trim()

  return string
}