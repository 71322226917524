import { useEffect, useState } from 'react';

// Increases value by given amount per second

export default function useIncreaseBy(value: number, increaseBy: number) {
  const [newValue, setNewValue] = useState(0);
  
  const incrementTime = Math.round(1000 / increaseBy);
  
  useEffect(() => {
    setNewValue(value)
  }, [value]);


  useEffect(() => {
    const interval = setInterval(() => {
      setNewValue(prevValue => prevValue + 1)
    }, incrementTime);

    return () => clearInterval(interval);
  }, [incrementTime])


  return Math.round(newValue);
}
  