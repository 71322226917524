import { links } from './'
import SaloonImage from '../../public/assets/images/body_types/saloon.png'
import EstateImage from '../../public/assets/images/body_types/estate.png'
import HatchbackImage from '../../public/assets/images/body_types/hatchback.png'
import SUVImage from '../../public/assets/images/body_types/suv.png'
import MPVImage from '../../public/assets/images/body_types/mpv.png'

const bodyTypes = [
  { name: 'Hatchback', image: HatchbackImage.src, link: links.hatchback, event: 'homeHatchbackClick' },
  { name: 'Saloon', image: SaloonImage.src, link: links.saloon, event: 'homeSaloonClick' },
  { name: 'Estate', image: EstateImage.src, link: links.estate, event: 'homeEstateClick' },
  { name: 'SUV', image: SUVImage.src, link: links.suv, event: 'homeSUVClick' },
  { name: 'MPV', image: MPVImage.src, link: links.mpv, event: 'homeMPVClick' }
]

export default bodyTypes