
const analyticsActions = {
  session_started: 'session_started',
  vehicle_compare: 'vehicle_compare',
  vehicle_search: 'vehicle_search',
  vehicle_filter: 'vehicle_filter',
  vehicle_featured_click: 'vehicle_featured_click',
  vehicle_call_dealership: 'vehicle_call_dealership',
  video_view: 'video_view',
  
  ad_event: 'ad_event',
  ad_type : {
    card: 'card',
    banner: 'banner',
  },
  ad_action: {
    click: 'click',
    impression: 'impression',
  }
}

export default analyticsActions;