import { useCallback, useEffect, useMemo, useState } from "react";

interface adSlotProps {
	mapping: any;
	sizes: any;
	adId: string;
	displayId: string;
	isTransitioning: boolean;
	make?: string; // slugified makes separated by commas
	model?: string; // slugified models separated by commas
	commercial?: boolean;
	listingPage?: boolean;
	thankYouPage?: boolean;
	evComparisonPage?: boolean;
	journeysPage?: boolean;
	articlePage?: boolean;
	videoPage?: boolean;
	homePage?: boolean;
	askNevoPage?: boolean;
	helpAdvicePage?: boolean;
	sideBanner?: boolean;
	evShowPage?: boolean;
}

export default function useAdSlot({
	mapping,
	sizes,
	adId,
	displayId,
	isTransitioning,
	make,
	model,
	commercial,
	sideBanner,
	listingPage,
	thankYouPage,
	evComparisonPage,
	journeysPage,
	articlePage,
	videoPage,
	homePage,
	helpAdvicePage,
	askNevoPage,
	evShowPage
}: adSlotProps) {
	const [breakpoint, setBreakpoint] = useState(0);

	useEffect(() => {
		if (!isTransitioning && typeof window !== undefined) {
			const { googletag } = window;

			if (!googletag) {
				console.error("Google Ad Tag is not defined");
				return;
			}

			googletag.cmd.push(() => {
				// building the mapping
				const adMapping = googletag.sizeMapping();
				Object.keys(mapping).forEach((breakpoint) => {
					adMapping.addSize([Number(breakpoint), 0], [mapping[breakpoint]]);
				});
				const builtMapping = adMapping.build();

				// slot level
				const pubdas = googletag.pubads();
				const adSlot = googletag.defineSlot(adId, sizes, `div-gpt-ad-${displayId}`);
				adSlot.defineSizeMapping(builtMapping);
				adSlot.addService(pubdas);

				if (!!model) adSlot.setTargeting("model", [...model.split(",")]);
				else if (!!make && make !== "all") adSlot.setTargeting("make", [...make.split(",")]);

				if (commercial) adSlot.setTargeting("commercial", ["true"]);
				else if (commercial === false) adSlot.setTargeting("passenger", ["true"]);

				if (homePage) adSlot.setTargeting("home", ["true"]);
				if (helpAdvicePage) adSlot.setTargeting("helpadvice", ["true"]);
				if (listingPage) adSlot.setTargeting("search", ["true"]);
				if (thankYouPage) adSlot.setTargeting("thankyou", ["true"]);
				if (evComparisonPage) adSlot.setTargeting("evcomparison", ["true"]);
				if (journeysPage) adSlot.setTargeting("journeys", ["true"]);
				if (articlePage) adSlot.setTargeting("article", ["true"]);
				if (videoPage) adSlot.setTargeting("video", ["true"]);
				if (askNevoPage) adSlot.setTargeting("asknevo", ["true"]);
				if (sideBanner) adSlot.setTargeting("sidebanner", ["true"]);
				if (evShowPage) adSlot.setTargeting("evshow", ["true"]);

				// page level
				pubdas.enableSingleRequest();
				googletag.enableServices();
			});

			googletag.cmd.push(() => {
				googletag.display(`div-gpt-ad-${displayId}`);
			});
		}
	}, [
		mapping,
		sizes,
		adId,
		displayId,
		isTransitioning,
		make,
		model,
		commercial,
		sideBanner,
		listingPage,
		thankYouPage,
		evComparisonPage,
		journeysPage,
		articlePage,
		videoPage,
		homePage,
		helpAdvicePage,
		askNevoPage,
		evShowPage
	]);

	const breakpoints = useMemo(() => {
		return Object.keys(mapping).map(Number);
	}, [mapping]);

	const onResize = useCallback(() => {
		const { googletag } = window;
		if (!googletag) return;

		const callback = (newBreakpoint: number) => {
			googletag.cmd.push(() => {
				googletag.pubads().refresh();
			});
			setBreakpoint(newBreakpoint);
		};

		for (let i = 0; i < breakpoints.length - 1; i++) {
			if (window.innerWidth >= breakpoints[i] && window.innerWidth < breakpoints[i + 1]) {
				if (breakpoint !== breakpoints[i]) {
					callback(breakpoints[i]);
				}
				return;
			}

			// if we're at the last breakpoint, and the window is larger than the last breakpoint
			if (i === breakpoints.length - 2 && window.innerWidth >= breakpoints[i + 1]) {
				if (breakpoint !== breakpoints[i + 1]) {
					callback(breakpoints[i + 1]);
				}
				return;
			}
		}
	}, [breakpoint, breakpoints]);

	useEffect(() => {
		window.addEventListener("resize", onResize);
		window.addEventListener("orientationchange", onResize);

		return () => {
			window.removeEventListener("resize", onResize);
			window.removeEventListener("orientationchange", onResize);
		};
	}, [onResize]);
}
