
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import axios, { Canceler } from 'axios';
import useCancel from '../Hooks/CancelFetch';
import Backend from "../../utils/Backend";
import { toast } from "react-toastify";
import { vehicleTypes } from '../../helper/constants';
import { financeSearchMakes } from '../../helper/filters';

import IOption from "../../interfaces/IOption";
import iFilters from '../../interfaces/iFilters';

/**
 * @description Manages and fetches makes and models
 * 
 * @param makes
 * @param setModels 
 * @param vehicleType 
 * @param fetchModels 
 * @param setFetchModels 
 * @param filters 
 * @param setRefresh 
 * @returns Well managed makes and models based on selected makes
 */

export default function MakesModelsTool(
  makes: IOption[],
  setModels: (models: IOption[]) => void,
  vehicleType: string,
  fetchModels?: boolean,
  setFetchModels?: (fetchModels: boolean) => void,
  filters?: iFilters,
  setRefresh?: (refresh: boolean) => void,
) {
  const [makeOptions, setMakeOptions] = useState<IOption[]>([]);
  const [modelOptions, setModelOptions] = useState<IOption[]>([]);

  const cancelModelsRef = useRef<Canceler>();

  const commercial = useMemo(() => vehicleType === vehicleTypes.commercial, [vehicleType])

  // Fetching/Managing makes
  useEffect(() => {
    if (vehicleType === vehicleTypes.finance) {
      setMakeOptions(financeSearchMakes)
      return;
    }

    const { promise, cancel } = Backend.getAllMakes(commercial)
    
    promise.then(({data}) => {
      const fetchedManufactures: IOption[] = []

      if (!data.content) {
        throw new Error("No manufactures were retrieved")
      }

      for (const manufacturer of data.content) {
        fetchedManufactures.push(
          { label: manufacturer.name, value: manufacturer.id }
        )
      }

      fetchedManufactures.unshift({ label: "All", value: "all" })

      setMakeOptions(fetchedManufactures)

    }).catch((err) => { 
      if (axios.isCancel(err)) return

      toast.error("Failed to fetch Makes")
      if (setRefresh) setRefresh(true)
    })

    return cancel;
  }, [setRefresh, commercial, vehicleType]);

  useCancel(cancelModelsRef.current)
  
  const fetchModelsByMake = useCallback(() => {
    let vehicle_type : string | undefined // private or commercial in this case, if any other means fetch both private and commercial vehicles

    if (vehicleType === vehicleTypes.private || vehicleType === vehicleTypes.commercial) {
      vehicle_type = vehicleType
    }

    const { promise, cancel } = Backend.getVehicles(
      makes, vehicle_type, 
      true, filters, 
      [], undefined, undefined, // no models, start, end points
    )
    cancelModelsRef.current = cancel;

    promise.then(({data}) => {
      if (Object.keys(data?.content?.vehicles)?.length !== 0) {
        const fetchedModels = data.content.vehicles
        fetchedModels.unshift({ label: "All", value: "all" })
        setModelOptions(data.content.vehicles)
      }
      else {
        setModelOptions([])
      }
    }).catch((err) => {
      if (axios.isCancel(err)) return;
      
      toast.error("Failed to fetch Models")
    })
  }, [makes, filters, vehicleType])


  // Fetches new models based on selected make
  useEffect(() => {
    setModels([])
    setModelOptions([]);

    if (makes.length) {
      // allows to wait for Vehicles to load (prevents fetch requests collision and fail)
      if (fetchModels === undefined) {
        fetchModelsByMake()
      }
      else if (fetchModels) {
        // send request based on fetchModels value
        fetchModelsByMake()
        if (setFetchModels) setFetchModels(false)
      }
    }
  }, [makes, setModels, fetchModels, fetchModelsByMake, setFetchModels]);

  return {
    makeOptions,
    modelOptions
  }
};
