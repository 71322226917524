import { useEffect, useState } from "react";
import { getDeviceType } from "../../../utils/UserManager";

const useDevice = () => {
  const [device, setDevice] = useState<string>("");

  useEffect(() => {
    setDevice(getDeviceType())
  }, []); 

  return device;
}

export default useDevice;