import React, { useContext, useRef, useMemo } from "react";

import { TransitionContext } from "../Context/TransitionState";
import useAdSlot from "../Hooks/AdSlot";
import { getGoogleAd } from "../../lib/google-ads";

import styles from "./styles.module.scss";

interface GoogleAdProps {
	adId: string;
	displayId?: string;
	debounce?: boolean;
	sideBanner?: boolean;
	make?: string;
	model?: string;
	commercial?: boolean;
	homePage?: boolean;
	helpAdvicePage?: boolean;
	listingPage?: boolean;
	thankYouPage?: boolean;
	evComparisonPage?: boolean;
	journeysPage?: boolean;
	articlePage?: boolean;
	videoPage?: boolean;
	askNevoPage?: boolean;
	evShowPage?: boolean;
	className?: string;
}

export default function GoogleAd({
	adId,
	displayId = "0",
	debounce,
	sideBanner,
	make,
	model,
	commercial,
	homePage,
	listingPage,
	thankYouPage,
	evComparisonPage,
	journeysPage,
	articlePage,
	videoPage,
	helpAdvicePage,
	askNevoPage,
	evShowPage,
	className
}: GoogleAdProps) {
	const { isTransitioning, deboucnedIsTransitioning } = useContext(TransitionContext);

	const ad = useMemo(() => getGoogleAd(adId, listingPage, sideBanner), [adId, listingPage, sideBanner]);

	const adRef = useRef<HTMLDivElement>(null);

	useAdSlot({
		mapping: ad.mapping,
		sizes: ad.sizes,
		adId: adId,
		displayId: displayId,
		isTransitioning: debounce ? deboucnedIsTransitioning : isTransitioning,
		make,
		model,
		commercial,
		listingPage,
		thankYouPage,
		evComparisonPage,
		journeysPage,
		articlePage,
		videoPage,
		homePage,
		helpAdvicePage,
		askNevoPage,
		evShowPage,
		sideBanner
	});

	// Hide the ad container if the ad is not loaded
	const isAdLoaded = !!adRef.current?.querySelector("div > iframe");
	const style = { display: isAdLoaded ? "" : "none" };

	return (
		<div className={`${styles.googleAdHolder} ${className}`} style={style}>
			<div ref={adRef} id={`div-gpt-ad-${displayId}`} className={styles.googleAd} />
		</div>
	);
}
