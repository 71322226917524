import { IItems } from "../../interfaces/IMenuItems"


// vehicle items
export const vehicleItems : IItems[] = [
  {name: 'All-Electric', event: 'electricCar', href: "/vehicles/all?order_by=most-recent&fuel_type=BEV", aRef: true,},
  {name: 'Plug-In Hybrids', event: 'plugInHybrids', href: "/vehicles/all?order_by=most-recent&fuel_type=PHEV", aRef: true,},
  {name: 'Used EVs', event: 'usedEVs', href: "/used-evs"},
  {name: 'Commercial', event: 'commercial', href: "/commercial-vehicles/all"},
]

export const qaVehicleItems : IItems[] = []
// end of vehicle items


// learn items
export const helpItems: IItems[] = [
  {name: 'Electric Vehicle Show', event: 'electricVehicleShow', href: "/electric-vehicle-show", blue: true},
  {name: 'Articles', event: 'articles', href: "/learn/articles"},
  {name: 'Video Review', event: 'video', href: "/learn/video-review"},
  {name: '#AskNevo', event: 'askNevo', href: "/learn/ask-nevo"},
  {name: 'News Feed', event: 'newsFeed', href: "/learn/newsfeed"},
]

export const qaHelpItems : IItems[] = []
// end of learn items


// tools items
export const toolsItems: IItems[] = [
  // {name: 'EV Guide', event: 'evGuide', href: "/ev-guide"},
  {name: 'Journey Planner', event: 'journeyPlanner', href: "/journeys"},
  {name: 'Compare EVs', event: 'compareEv', href: "/vehicles-comparison"},
]

export const qaToolsItems : IItems[] = []
// end of tools items


// services items
export const servicesItems: IItems[] = [
  { name: 'Finance', event: 'finance', href: "/partner/bank-of-ireland" },
  { name: 'Insurance', event: 'insurance', href: "/partner/insurance" },
  { name: 'Car Servicing', event: 'carServicing', href:"/partner/best-drive"}
  // { name: 'Energy', event: 'energy', href: "/energy" },
]

export const qaServicesItems : IItems[] = []
// end of services items

// company items
export const companyItems: IItems[] = [
  { name: 'Our Mission', event: 'ourMission', href: "/our-mission" },
  { name: 'Contact Us', event: 'contactUs', href: "/contact-us" },
  // { name: 'Feedback', event: 'feedback', href: "/feedback" },
  { name: 'Terms', event: 'terms', href: "/terms" },
  { name: 'Privacy', event: 'privacy', href: "/privacy" },
  { name: 'Cookies', event: 'cookies', href: "/cookies" },
]

export const qaCompanyItems : IItems[] = []
// end of company items