import React from 'react';

interface ISvgIcon {
  color: string;
}

const YouTube = ({color = "#8d949d"} : ISvgIcon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40.403" height="28.289" viewBox="0 0 40.403 28.289">
      <path id="youtube" d="M39.571-1.655a5.062,5.062,0,0,0-3.561-3.561C32.849-6.082,20.2-6.082,20.2-6.082s-12.646,0-15.808.832A5.164,5.164,0,0,0,.833-1.655C0,1.506,0,8.063,0,8.063s0,6.59.832,9.718a5.062,5.062,0,0,0,3.561,3.561c3.195.865,15.808.865,15.808.865s12.646,0,15.808-.832a5.062,5.062,0,0,0,3.561-3.561C40.4,14.652,40.4,8.1,40.4,8.1s.033-6.59-.832-9.751ZM16.175,14.12V2.005L26.692,8.063Zm0,0" transform="translate(-0.001 6.082)" fill={color} />
    </svg>
  )
}

export default YouTube;