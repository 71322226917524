
import React from 'react';

import styles from './styles.module.scss';

import Facebook from '../../public/assets/icons/facebook';
import Instagram from '../../public/assets/icons/instagram';
import TikTok from '../../public/assets/icons/tiktok';
import LinkedIn from '../../public/assets/icons/linkedin';
import Twitter from '../../public/assets/icons/twitter';
import YouTube from '../../public/assets/icons/youtube';

import { event } from '../../lib/gtag';

interface SocialMediaProps {
  whiteVersion?: boolean;
  noFollow?: boolean;
  className?: string;
}

export default function SocialMedia ({ whiteVersion, noFollow, className } : SocialMediaProps) {
  const onMediaClick = (url: string, evt: string) => {
    event(evt)

    const website = window.open(url, "_blank");
    website?.focus();
  }
  
  const color = "#8D949D"; 

  return (
    <div className={`${styles.socialMedia} ${whiteVersion && styles.whiteVersion} ${className}`}>
      { !noFollow && <h1 className="nav-button">Follow</h1> } 
      
      <div className={`${styles.socialMediaHolder} ${noFollow && styles.noFollowHolder}`}>
        <div onClick={() => onMediaClick("https://www.facebook.com/nevoireland", "clickedOnFacebook")}>
          <Facebook color={color} />
        </div>
        
        <div onClick={() => onMediaClick("https://www.instagram.com/nevoireland", "clickedOnInstagram")}>
          <Instagram color={color} />
        </div>

        <div onClick={() => onMediaClick("https://www.tiktok.com/@nevoireland", "clickedOnTikTok")}>
          <TikTok color={color} />
        </div>

        <div onClick={() => onMediaClick("https://www.linkedin.com/company/nevo-co", "clickedOnLinkedin")}>
          <LinkedIn color={color} />
        </div>

        <div onClick={() => onMediaClick("https://twitter.com/nevoireland", "clickedOnTwitter")}>
          <Twitter color={color} />
        </div>

        <div onClick={() => onMediaClick("https://www.youtube.com/@NevoEVReviewIreland", "clickedOnYouTube")}>
          <YouTube color={color} />
        </div>
      </div>
    </div>
  );
}
