import { commercialVehicles } from "./constants";

export function formatCurrency(price: number) {
  if (price <= 0) return 'TBC'
  
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  })
}

export function formatCurrency2Digits(price?: number) {
  if (!price || price <= 0) return 'TBC'
  
  return price.toLocaleString('en-US', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
  })
}

export function formatPrice(price: number) {
  return price > 0 ? formatCurrency(price) : 'Price TBC'
}

export function formatFromPrice(price:number) {
  return price > 0 ? `From ${formatCurrency(price)}` : 'Price TBC'
}

export function formatOptionPrice(price: number) {
  return price > 0 ? `${formatCurrency(price)} Price` : 'Price TBC'
}

// removes repiting words (Land Rover Range Rover Evoque -> Land Rover Range Evoque)
export function shortenTitle(make: string, model: string) {
  const title = make + " " + model;

  return title.split(' ')
    .filter((word, index, array) => {
      return array.indexOf(word) === index
    })
  .join(' ');
}

export function formatTrunk(trunk: number, body_type: string) {
  if (trunk <= 0) return '-';

  if (commercialVehicles.includes(body_type)) {
    return `${trunk}m³`
  }

  return `${trunk}L`
}

// returns ' In EV mode' if the vehicle's fuel_type is PHEV
export function formatRange(fuel_type: string) {
  return fuel_type === "PHEV" ? "in EV mode" : ""
}