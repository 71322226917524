import { useState } from "react";

export default function CountUpAnimation(target: number, start: boolean, speed = 600) {
  const [animationNumber, setAnimationNumber] = useState(0);

  if (!start) return 0;

  // rounds to 2 decimal places
  function roundTo2(num: number) {
    const m = Number((Math.abs(num) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(num);
  }

  const step = roundTo2(target / speed);
  
  if (animationNumber < target) {
    requestAnimationFrame(() => {
      setAnimationNumber(animationNumber + step);
    })
  }
  else {
    return target;
  }

  return Math.round(animationNumber);
}