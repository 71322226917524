/* eslint-disable @next/next/no-img-element */

import React, { useEffect, useMemo, useState } from 'react';
import Link from 'next/link';

import Backend from '../../../../../utils/Backend';
import slugify from 'react-slugify';
import { formatPrice, formatRange } from '../../../../../helper/vehicle_formatter';

import { event } from '../../../../../lib/gtag';

import LearnMoreButton from '../../../../LearnMoreButton';
import Loader from '../../../../Loader';

import styles from '../styles.module.scss';


interface IFinanceVehicleCard {
  vehicle: any
}

export default function FinanceVehicleCard({ vehicle } : IFinanceVehicleCard) {
  const [imageURL, setImageURL] = useState("")

  const {
    id, make, model, 
    fuel_type, range,
    
  } = vehicle; 

  const finance_price = useMemo(() => {
    let finance_price = 0;
    if (vehicle.finance_data) {
      finance_price = vehicle.finance_data.monthly_cost;
    }
    return finance_price;
  }, [vehicle])

  useEffect(() => {
    setImageURL(Backend.getImage(vehicle, "28"))
  }, [vehicle])

  const generatedURL = `/finance-vehicle/${slugify(make)}/${slugify(model)}/${id}`;

  return (
    <Link href={generatedURL} passHref>
      <a className={styles.refHolder}>
        <div className={styles.vehicleCard}>
          <div className={styles.vehicleImage} style={{ backgroundImage: `url(${imageURL})` }}>
            {
              imageURL === ""
              ? <Loader />
              : <>&nbsp;</>
            }
          </div>

          <div className={styles.vehicleDescription}>
            <p className={styles.vehicleType}>
              {
                fuel_type === "BEV" ? "All-Electric" :
                fuel_type === "PHEV" ? "Plug-in Hybrid" : fuel_type
              }
            </p>

            <h1 className={styles.vehicleTitle}>{ make + " " + model }</h1>

            <p className={styles.vehicleRange}>
              { parseInt(range) > 0 ? `Up to ${range}` : '- ' }km Range {formatRange(fuel_type)}
            </p>
            
            <p className={styles.pcpTxt}>
              Personal Contract Plan (PCP)
            </p>

            <p className={`${styles.vehiclePrice} ${styles.financePrice}`}>
              { formatPrice(finance_price) } { finance_price && 'per Month' } 
            </p>

            <LearnMoreButton 
              className={styles.learnMoreBtn}
              onClick={() => event("clickedOnLearnMore")} 
            />
          </div>
        </div>
      </a>
    </Link>
  )
};
