import React, { Fragment } from "react";

import { dynamicEvent } from "../lib/gtag";

import PostCard from "../components/Cards/PostCard";
import VehicleCard from "../components/Cards/Vehicle/VehicleCard/Nevo";
import UsedVehicleCard from "../components/Cards/Vehicle/UsedVehicleCard";
import FinanceVehicleCard from "../components/Cards/Vehicle/VehicleCard/Finance";
import PostsHolder from "../components/PostsHolder";
import GoogleAd from "../components/GoogleAd";

import { googleAdIds, vehicleTypes } from "../helper/constants";
import IPost from "../interfaces/IPost";
import IOption from "../interfaces/IOption";

export const renderPage = (page: any, contentCount: number) => {
	return page.sections.map((section: any) => {
		return renderSection(section, contentCount);
	});
};

export const renderSection = (section: any, contentCount: number) => {
	return section.components.map((component: any) => {
		return renderComponent(component, contentCount);
	});
};

export const renderComponent = (component: any, contentCount: number) => {
	const slug = component.slug;

	if (Object.keys(component.content?.partners).length > 0) {
		return (
			<div className="component" key={component.name}>
				<PostsHolder title={component.name} seeAllPathname={slug}>
					{renderPartners(component.content.partners.slice(0, contentCount))}
				</PostsHolder>
			</div>
		);
	} else if (Object.keys(component.content?.blogs).length > 0) {
		return (
			<div className="component" key={component.name}>
				<PostsHolder title={component.name} seeAllPathname={slug}>
					{renderPosts(component.content.blogs.slice(0, contentCount))}
				</PostsHolder>
			</div>
		);
	}
};

export const renderPosts = (posts: IPost[]) => {
	return posts.map((post: IPost) => {
		return <PostCard key={post.name} url={`/post/${post.url}`} thumbnail_image_url={post.thumbnail_image_url} name={post.name} description={post.description} />;
	});
};

export const renderPartners = (partners: any[]) => {
	return partners.map((partner: any) => {
		return <PostCard key={partner.id} url={`/partner/${partner.slug}`} thumbnail_image_url={partner.logo_image} name={partner.name} description={partner.description} />;
	});
};

export const renderJourneys = (journeys: IPost[]) => {
	const trackRoute = (route: string) => {
		dynamicEvent("Journeys Routes", `Clicked on ${route} route`);
	};

	return journeys.map((journey: IPost) => {
		return (
			<PostCard
				key={journey.name}
				url={journey.url}
				thumbnail_image_url={journey.thumbnail_image_url}
				name={journey.name}
				description={journey.description}
				trackEvent={() => trackRoute(journey.name)}
			/>
		);
	});
};

const renderVehicleWithAd = (vehicle: JSX.Element | null, index: number) => {
	return (
		<Fragment key={`${googleAdIds.horizontal_banner}-${index}`}>
			<GoogleAd className="ad-between-vehicles" adId={googleAdIds.horizontal_banner} displayId={`${index}`} listingPage />
			{vehicle}
		</Fragment>
	);
};

export const renderVehicles = (vehicles: any[], vehicleType = vehicleTypes.private, smallDesktop?: boolean) => {
	let renderedVehicles: any[] = [];

	if (vehicleType === vehicleTypes.used) {
		renderedVehicles = vehicles.map((vehicle) => {
			return <UsedVehicleCard key={vehicle.id} vehicle={vehicle} />;
		});
	}

	if (vehicleType === vehicleTypes.finance) {
		renderedVehicles = vehicles.map((vehicle) => {
			return <FinanceVehicleCard key={vehicle.id} vehicle={vehicle} />;
		});
	}

	if (vehicleType === vehicleTypes.commercial || vehicleType === vehicleTypes.private) {
		renderedVehicles = vehicles.map((vehicle) => {
			return <VehicleCard key={vehicle.id} vehicle={vehicle} commercial={vehicleType === vehicleTypes.commercial} />;
		});
	}

	return renderedVehicles.map((vehicle, index) => {
		if (smallDesktop) {
			// 1/2 vehicles per row
			if (index !== 0 && index % 8 === 0 && index < 8 * 5) {
				return renderVehicleWithAd(vehicle, index); // rendering ad every 8 vehicles to keep symmetry
			}
		} else {
			if (index !== 0 && index % 9 === 0 && index < 9 * 5) {
				// 3 vehicles per row
				return renderVehicleWithAd(vehicle, index); // rendering ad every 9 vehicles to keep symmetry
			}
		}
		return vehicle;
	});
};

export const renderMultiSelector = (options: IOption[], characterLimit = 20) => {
	let textOptions = "";

	// create text like (e.g. "BMW, Audi, +2" or "BMW, Audi")
	for (let option = 0; option < options.length; ++option) {
		if ((textOptions + options[option].label).length > characterLimit && option !== 0) {
			textOptions += `+${options.length - option}`;
			break;
		} else {
			textOptions += options[option].label;
			textOptions += options.length === option + 1 ? "" : ", ";
		}
	}

	return textOptions;
};
