import slugify from "react-slugify";
import IOption from "../interfaces/IOption";

/**
 * Generates a URL for a vehicle.
 * Pattern: make,model,variant_id
 * @param vehicle -- vehicle object that contains all the info
 * @param number? -- if provided it'll be used to add 'EV<number>' at the beginning of the URL
 * @returns URL string
 */
export default function generateURL(vehicle: any, number?: number) {
  let url = `${slugify(vehicle.make)}/${slugify(vehicle.model)}/${vehicle.third_party.variantId}`;

  if (number) {
    url = `EV${number}=${url}`;
  }

  return url;
}

export function slugifyArray(array: IOption[]) {
  let slugifiedString = "";
  
  array.map((value, index) => {
    slugifiedString += (index !== array.length - 1) 
      ? slugify(value.label) + "%2C" 
      : slugify(value.label) 
  })

  return slugifiedString;
}

export function processArrayFilter(array: string[], options: IOption[]) {
  const searchOptions: IOption[] = [];

  array.forEach(queryItem => {
    const item = options.find(option => {
      return slugify(option.label) === queryItem;
    })
    if (item) searchOptions.push(item);
  })

  return searchOptions;
}