import deepCopy from "../helper/deep_copy";

const ads: any = {
	"/22866222029/horizontal-v3": {
		sizes: [
			[970, 250],
			[728, 90],
			[300, 250]
		],
		mapping: {
			// literally breakpoints
			0: [300, 250],
			768: [728, 90],
			1024: [970, 250]
		}
	}
};

export const getGoogleAd = (id: string, listingPage = false, sideBanner = false) => {
	const ad = deepCopy(ads[id]); //* to ensure each ad has its own mapping

	// Updating size mapping based on page type
	if (sideBanner) {
		// for side banners it's always 300x250
		ad.mapping = {
			0: [300, 250]
		};
	} else if (listingPage) {
		ad.mapping = {
			0: [300, 250],
			800: [728, 90],
			1441: [970, 250]
		};
	}

	return ad;
};

export default ads;
