import React from 'react'

import styles from './styles.module.scss'
import VehicleArrow from '../../public/assets/icons/vehicle-arrow';

interface ILearnMoreButton {
  className?: string,
  onClick?: any,
  btnTxt?: string,
  blueBtn?: boolean,
  arrowColor?: string
}

export default function LearnMoreButton({ className, onClick, btnTxt = 'Learn More', blueBtn, arrowColor = '#1A1C1F' }: ILearnMoreButton) {
  return (
    <div className={`${styles.vehicleButton} blue-button ${className} ${blueBtn && styles.blueBtn}`} onClick={onClick}>
      <span>{ btnTxt }</span>
      <div><VehicleArrow fill={arrowColor} /></div>
    </div>
  )
}