import Geocode from "react-geocode";

if (process.env.NEXT_PUBLIC_GOOGLE_GEOCODER_API_KEY) {
  Geocode.setApiKey(process.env.NEXT_PUBLIC_GOOGLE_GEOCODER_API_KEY);
  Geocode.setLocationType("ROOFTOP");
}

function getCoords() {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    } 
    else {
      reject();
    }
  });
}

export default async function getLocation() {
  let position: any 
  
  try {
    position = await getCoords()
  } catch(error) {
    console.log("User refused to share location")
  }

  if (!position) return;
  
  const { latitude, longitude } = position.coords;

  let response: any;
  try {
    response = await Geocode.fromLatLng(latitude, longitude);
    
    if (!!!response?.results || response.results.length === 0) {
      return
    }
  } catch (error) {
    console.error("Error Happend during looking for user's location");
    return
  }

  const location = response.results[0];

  let county = "Unknown"
  let country = "Unknown"
  for (let address of location.address_components) {
    if (address.types.includes("administrative_area_level_1")) {
      county = address.long_name;
    }
    else if (address.types.includes("country")) {
      country = address.long_name;
    }
  }

  return {
    county,
    country,
  }
}
