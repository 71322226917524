
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import Link from 'next/link';

import useMobile from '../../Hooks/Mobile';
import useScrolledTo from '../../Hooks/ScrolledTo';
import SwipingPanels from '../../SwipingPanels';

import { dynamicEvent } from '../../../lib/gtag';

import styles from './styles.module.scss'
import JourneyPlannerImage from '../../../public/assets/images/journey-planner-tool.png'
import CompareEVImage from '../../../public/assets/images/compare-ev-tool.png'

const content = [
  {
    title: "Journey Planner",
    header: "Plan your next electric road trip with ease using Nevo’s Journey Planner.",
    text: "Simply enter your trip details and vehicle information, and we’ll find the best route for you with any necessary charging stops included.",
    image: JourneyPlannerImage.src,
    cta: 'Plan your journey',
    link: '/journeys'
  },
  {
    title: "Compare Vehicles",
    header: "Select and compare all your favourite electric vehicles in one place.",
    text: "Nevo’s unique comparison tool allows you to easily compare the key features of all your favourite electric vehicles, making it easier to find the EV for you.",
    image: CompareEVImage.src,
    cta: 'Compare vehicles',
    link: '/vehicles-comparison'
  }
  // {
  //   title: "EV Guide",
  //   header: "All roads lead to electric. Let’s help you get there with the EV for you.",
  //   text: "As Ireland’s only dedicated electric vehicle platform, we are committed to help you find the perfect electric vehicle that matches your daily motoring needs, lifestyle and budget.",
  //   image: EVGuideImage.src,
  //   cta: 'EV guide for you',
  //   link: '/ev-guide'
  // }
]

export default function Tools()  {
  const [slide, setSlide] = useState(0)

  const sectionRef = useRef<HTMLDivElement>(null)

  const lineRef = useRef<HTMLDivElement>(null)
  const whyEVRef = useRef<HTMLDivElement>(null)
  const costRef = useRef<HTMLDivElement>(null)
  const speedRef = useRef<HTMLDivElement>(null)

  const tablet = useMobile(900);

  const startSlideShow = useScrolledTo(sectionRef)

  const getPositionX = (element: HTMLDivElement) => {
    return element.getBoundingClientRect().left
  }
  const getElementWidth = (element: HTMLDivElement) => {
    return element.getBoundingClientRect().width
  }
  
  const moveUndeline = useCallback(() => {
    const refsArray = [whyEVRef, costRef, speedRef]

    const currentRef = refsArray[slide]

    if (currentRef.current && lineRef.current) {
      const positionX = getPositionX(currentRef.current)
      const width = getElementWidth(currentRef.current)

      lineRef.current.style.left = `${positionX}px`
      lineRef.current.style.width = `${width}px`
    }
  }, [slide])

  
  useEffect(() => {
    if (tablet !== undefined && !tablet) {
      moveUndeline()

      window.addEventListener('resize', moveUndeline)
      return () => window.removeEventListener('resize', moveUndeline)
    }
    // re-setting default width for underline for tablet
    else if (lineRef?.current) {
      lineRef.current.style.width = '100%'
    }
  }, [moveUndeline, tablet])


  const handleNextSlide = useCallback(() => {
    if (slide < content.length - 1) {
      setSlide(slide + 1)
    }
    else setSlide(0)
  }, [slide])
  
  // 15 seconds slide animation 
  useEffect(() => {
    if (startSlideShow) {
      const timeout = setTimeout(handleNextSlide, 15000)
      return () => clearTimeout(timeout)
    }
  }, [startSlideShow, handleNextSlide])

  const onSlideClick = (slideNumber: number) => {
    if (slide !== slideNumber) setSlide(slideNumber)
  }

  const trackBenefit = (title: string) => {
    dynamicEvent("Home EV Benefits", `Clicked on ${title}`)
  }

  const renderSlide = (s: number) => {
    return (
      <div key={s} className={styles.slide}>
        <h2>{content[s].header}</h2>
        <p>{content[s].text}</p>
        <Link href={content[s].link} passHref>
          <button className={`blue-button`} onClick={() => trackBenefit(content[s].title)}>
            {content[s].cta}
          </button>
        </Link>
      </div>
    )
  }
 
  return (
    <>
      <Head>
        {content.map(({ image }) => {
          return <link key={image} rel="preload" href={image} as="image" />
        })}
      </Head>

      <div className={styles.presentation} ref={sectionRef}>
        { !tablet && (
          <div className={styles.contentControls}>
            <div className={styles.controlHolder} onClick={() => {onSlideClick(0)}}>
              <div className={styles.control} ref={whyEVRef}>
                <h2>Journey Planner</h2>
                <div 
                  className={
                    styles.underline + " " + (!tablet ? styles.active : (slide === 0 ? styles.active : ""))
                  }
                  ref={lineRef} 
                />
              </div>
            </div>
            
            <div className={styles.separetor} />

            <div className={styles.controlHolder} onClick={() => {onSlideClick(1)}}>
              <div className={styles.control} ref={costRef}>
                <h2>Compare Vehicles</h2>
                <div className={styles.underline + " " + (tablet && slide === 1 ? styles.active : "")} />
              </div>
            </div>
            
            {/* <div className={styles.separetor + " " + styles.lastSeparator} />

            <div className={styles.controlHolder} onClick={() => {onSlideClick(2)}}>
              <div className={styles.control} ref={speedRef}>
                <h2>EV Guide</h2>
                <div className={styles.underline + " " + (tablet && slide === 2 ? styles.active : "")} />
              </div>
            </div> */}
          </div>
        )}
      
        { !tablet ? (
          <div className={styles.content}>
            <div key={slide} className={styles.image} style={{ backgroundImage: `url(${content[slide].image})` }}>&nbsp;</div>

            <div className={styles.contentText}>
              { renderSlide(slide) }
            </div>
          </div>
        ) : (
          <SwipingPanels panel={slide} setPanel={setSlide}>
            {content.map((item, index) => (
              <div key={index} className={styles.content}>
                <h2 className={styles.mobileTitle}>{ item.title }</h2>

                <div key={slide} className={styles.image} style={{ backgroundImage: `url(${content[index].image})` }}>&nbsp;</div>

                <div className={styles.contentText}>
                  { renderSlide(index) }
                </div>
              </div>
            ))}
          </SwipingPanels>
        )}
      </div>
    </>
  )
};
