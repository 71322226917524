import React from 'react';

interface Image {
  color: string;
}

const Hamburger = ({color}: Image) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18.917" height="16.917" viewBox="0 0 18.917 16.917">
      <g transform="translate(-10 -11)">
        <g transform="translate(10.5 11.5)">
          <path style={{ fill: color, stroke: color}} d="M24.022,8.792H7.9A.9.9,0,1,1,7.9,7H24.022a.9.9,0,1,1,0,1.792Z" transform="translate(-7 -7)"/>
        </g>
        <g transform="translate(10.5 18.563)">
          <path style={{ fill: color, stroke: color}} d="M24.022,13.292H7.9a.9.9,0,0,1,0-1.792H24.022a.9.9,0,0,1,0,1.792Z" transform="translate(-7 -11.5)"/>
        </g>
        <g transform="translate(10.5 25.626)">
          <path style={{ fill: color, stroke: color}} d="M24.022,17.792H7.9A.9.9,0,0,1,7.9,16H24.022a.9.9,0,0,1,0,1.792Z" transform="translate(-7 -16)"/>
        </g>
      </g>
    </svg>
  )
}

export default Hamburger;