import React from "react";
// import Link from "next/link";

import FindCar from "../../FindCar";

// import { links } from "../../../helper/constants";

import styles from "./styles.module.scss";

export default function VehicleSearchPanel() {
  return (
    <div className={styles.vehicleSearchPanel}>
      <div className={`${styles.opacityBack} padding-top padding`}>
        <h1 className={`${styles.header} huge-header white`}>
          Seamless transition to electric driving, <span className='blue'>for everyone</span>.
        </h1>
        
        <FindCar className={styles.findCar} />
      </div>

      <div className={styles.evGuide}>
        {/* <h1 className={`${styles.evGuideHeader} big-header`}>
          We can guide you to find the right electric vehicle
        </h1>

        <Link href={links.ev_guide} passHref>
          <button className={`${styles.evGuideBtn} blue-button`}>
            Ev Guide For You
          </button>
        </Link> */}
      </div>
    </div>   
  )
}