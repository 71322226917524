import React from 'react';

interface ISvgIcon {
  color: string;
}

const Facebook = ({color = "#8d949d"} : ISvgIcon) => {
  return (
    <svg id="facebook-app-symbol" xmlns="http://www.w3.org/2000/svg" width="14.71" height="28.329" viewBox="0 0 14.71 28.329">
      <path id="f_1_" d="M46.839,28.329V15.408h4.335l.65-5.037H46.839V7.155c0-1.458.4-2.451,2.5-2.451H52V.2A36.136,36.136,0,0,0,48.116,0c-3.845,0-6.477,2.347-6.477,6.656V10.37H37.29v5.037h4.348V28.329Z" transform="translate(-37.29)" fill={color} />
    </svg>
  )
}

export default Facebook;