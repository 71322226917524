import React, { Fragment, useEffect, useState } from "react";
import Head from "next/head";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";

import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { tagManagerArgs } from "../lib/gtag";
import manageUser, { deleteUser } from "../utils/UserManager";
import startQA, { isQAStorage } from "../utils/QAManager";
import TransitionProvider from "../components/Context/TransitionState";
import WebsiteTypeProvider from "../components/Context/WebsiteType";

import QABanner from "../components/QABanner";
import ScrollUp from "../components/ScrollUp";
import { ToastContainer } from "react-toastify";
import smoothscroll from "smoothscroll-polyfill";

import "react-toastify/dist/ReactToastify.css";
import "../styles/globals.scss";
import "../styles/fonts.scss";

declare global {
	const Cookiebot: any;
	const YT: any;
	interface Window {
		google: any;
		gtag: any;
		googletag: any;
		QAMode: boolean | undefined;
	}
}

const MyApp = ({ Component, pageProps }: AppProps) => {
	const [campaignID, setCampaignID] = useState<string>();
	const [qa, setQA] = useState<boolean>(); // exists because it can be in dependency array

	const router = useRouter();

	// Enabling QA Mode
	useEffect(() => {
		if (router.isReady && qa === undefined) {
			// to access this code only once
			if (router.query.qa === "true") {
				startQA();
				setQA(true);

				// removing qa from query string
				const { pathname, query } = router;
				delete query.qa;
				router.replace({ pathname, query }, undefined, { shallow: true });
			} else if (isQAStorage()) {
				setQA(true);
				window.QAMode = true;
			} else {
				setQA(false);
				window.QAMode = false;
			}
		}
	}, [qa, router]);

	// Using Google Analytics only if not QA mode
	useEffect(() => {
		if (qa === false) {
			TagManager.initialize(tagManagerArgs);
			ReactGA.initialize(process.env.NEXT_PUBLIC_GA_TRACKING_ID || "UA-XXXXX-1");
		}
	}, [qa]);

	useEffect(() => {
		smoothscroll.polyfill(); // smoothes animations for Safari

		// redirect on main domain from http to https
		let href = window.location.href;
		if (!href.includes("localhost") && !href.includes("https")) {
			href = href.replace("http", "https");

			window.location.href = href;
		}
	}, []);

	// extracting campaign ID from query string
	useEffect(() => {
		if (router.isReady && !!!campaignID) {
			const campaign_id = router.query.campaign_id?.toString();

			// removing campaign_id from query string
			const { pathname, query } = router;
			delete query.campaign_id;
			router.replace({ pathname, query }, undefined, { shallow: true });

			setCampaignID(campaign_id || "none");
		}
	}, [router, campaignID]);

	// generating and storing Unique User ID (uuid) only if user gave the consent
	useEffect(() => {
		// Using CookieBot only if not QA mode
		if (qa !== false) return;

		if (!!campaignID) {
			// in this way it would fire callback only once
			const onAccept = () => {
				if (Cookiebot.consent.statistics) {
					manageUser(campaignID === "none" ? undefined : campaignID);
				} else {
					deleteUser();
				}
			};

			const onDecline = () => {
				deleteUser();
			};

			window.addEventListener("CookiebotOnAccept", onAccept, false);
			window.addEventListener("CookiebotOnDecline", onDecline, false);
		}
	}, [qa, campaignID]);

	return (
		<Fragment>
			<Head>
				<title>Nevo</title>

				{/*Meta*/}
				<meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no" />
				<meta name="description" content="Seamless transition to electric driving, for everyone." />
				<meta name="keywords" content="nevo, nevo.ie, nevo.co, Nevo Ireland, Nevo Electric Charging, Nevo Advisory, nevocharging.ie, electric cars, BEV, PHEV, electric car transition" />
				<meta name="author" content="Nevo" />
				<meta name="geo.region" content="IE" />

				<meta property="og:image" content="/assets/images/favicons/android-chrome-256x256.png" />
				<meta property="og:image:type" content="image/png" />
				<meta property="og:image:width" content="256" />
				<meta property="og:image:height" content="256" />
				<meta httpEquiv="X-UA-Compatible" content="IE=edge" />

				{/*Favicons*/}
				<link rel="shortcut icon" href="/assets/images/favicons/favicon.ico" />
				<link rel="icon" href="/assets/images/favicons/favicon.ico" />
				<link rel="apple-touch-icon" sizes="180x180" href="/assets/images/favicons/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/assets/images/favicons/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/assets/images/favicons/favicon-16x16.png" />
				<link rel="manifest" href="/assets/images/favicons/site.webmanifest" />
				<link rel="mask-icon" href="/assets/images/favicons/safari-pinned-tab.svg" color="#1A1C1F" />
			</Head>

			{qa && <QABanner />}

			<WebsiteTypeProvider>
				<TransitionProvider>
					<Component {...pageProps} />
				</TransitionProvider>
			</WebsiteTypeProvider>

			<ScrollUp />
			<ToastContainer />
		</Fragment>
	);
};
export default MyApp;
