import { useEffect, useState } from "react";

const useMobile = (width = 1024) => {
  const [mobile, setMobile] = useState<boolean>();

  useEffect(() => {
    setMobile(window.innerWidth <= width);
  }, [width]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setMobile(window.innerWidth <= width);
      }

      const handleOrientation = () => {
        setTimeout(handleResize, 100)
      }

      window.addEventListener("resize", handleResize);
      window.addEventListener('orientationchange', handleOrientation) 
      return () => {
        window.removeEventListener("resize", handleResize);
        window.removeEventListener('orientationchange', handleOrientation) 
      }
    }
  }, [mobile, width]); 

  return mobile;
}

export default useMobile;