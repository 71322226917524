/* eslint-disable @next/next/no-img-element */

import React, { useState, useRef } from 'react';
import Link from 'next/link'

import { event } from '../../lib/gtag';

import SubMenuItems from '../SubMenuItems';

import { 
  vehicleItems, qaVehicleItems,
  helpItems, qaHelpItems,
  toolsItems, qaToolsItems, servicesItems, qaServicesItems
} from '../SubMenuItems/menuItems'

import styles from './styles.module.scss'
import RightBlackArrow from '../../public/assets/icons/right-arrow-black.svg'


const SideMenuNav = ({ hideMenu, renderSideMenuHeader } : any) => {
  const [showVehicle, setVehiclesItems] = useState(false);
  const [showHelp, setHelpItems] = useState(false);
  const [showTools, setToolsItems] = useState(false);
  const [showServices, setServicesItems] = useState(false);

  const refItems = useRef<HTMLDivElement>(null);

  const displayItems = showVehicle || showHelp || showTools || showServices;

  const [VehicleItems]: any = SubMenuItems({ items: vehicleItems, qaItems: qaVehicleItems, eventType: 'SideMenu' });
  const [HelpItems]: any = SubMenuItems({ items: helpItems, qaItems: qaHelpItems, eventType: 'SideMenu' });
  const [ToolsItems]: any = SubMenuItems({ items: toolsItems, qaItems: qaToolsItems, eventType: 'SideMenu' });
  const [ServicesItems]: any = SubMenuItems({ items: servicesItems, qaItems: qaServicesItems, eventType: 'SideMenu' });

  const hideItems = () => {
    if (refItems && refItems.current) {
      refItems.current.classList.toggle(styles.itemsMenuHide);
    }

    setTimeout(() => {
      if (refItems && refItems.current) {
        refItems.current.style.display = "none";
      }

      setVehiclesItems(false);
      setHelpItems(false);
      setToolsItems(false);
      setServicesItems(false);
    }, 475);
  }

  const onNavItemClick = (action: string) => {
    event(action)
    hideMenu()
  }

  return (
    <>
      <div className={styles.sideMenuNav}>
        <div className="nav-button" onClick={() => {setVehiclesItems(true)}}>
          Browse Vehicles <img src={RightBlackArrow.src} alt="right arrow"/>
        </div>

        <div className="nav-button" onClick={() => {setHelpItems(true)}}>
          Help & Advice <img src={RightBlackArrow.src} alt="right arrow"/>
        </div>

        <div className="nav-button" onClick={() => {setToolsItems(true)}}>
          Tools <img src={RightBlackArrow.src} alt="right arrow"/>
        </div>

        <div className="nav-button" onClick={() => {setServicesItems(true)}}>
          Services <img src={RightBlackArrow.src} alt="right arrow"/>
        </div>
      </div>

      { displayItems &&
        <div className={styles.itemsMenu} ref={refItems}>
          <div className={styles.itemsHolder}>
            { renderSideMenuHeader() }

            <div onClick={hideItems} className={styles.goBackHolder}>
              <img src={RightBlackArrow.src} alt="right arrow"/>
            </div>
            
            { showVehicle && 
              <>
                <Link href="/vehicles/all" passHref>
                  <p onClick={() => onNavItemClick('vehiclesSideMenu')} className={styles.leadingNavButton}>
                    Browse Vehicles
                  </p>
                </Link>
                { VehicleItems }
              </> 
            }
            { showHelp && 
              <>
                <Link href="/learn/articles" passHref>
                  <p onClick={() => onNavItemClick('helpSideMenu')} className={styles.leadingNavButton}>
                    Help & Advice
                  </p>
                </Link>
                { HelpItems }
              </> 
            }
            { showTools && 
              <>
                <p className={`${styles.leadingNavButton} default-cursor`}>
                  Tools
                </p>
                { ToolsItems }
              </> 
            }
            { showServices && 
              <>
                <p className={`${styles.leadingNavButton} default-cursor`}>
                  Services
                </p>
                { ServicesItems }
              </> 
            }
          </div>
        </div>
      }
    </>
    
  )
};

export default SideMenuNav;