import React from 'react';

interface ISvgIcon {
  color: string;
}

const LinkedIn = ({color = "#8d949d"} : ISvgIcon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29.595" height="28.288" viewBox="0 0 29.595 28.288">
      <g id="linkedin" transform="translate(0)">
        <path id="Path_3570" data-name="Path 3570" d="M3.589-.668a3.307,3.307,0,1,0-.083,6.6h.041a3.308,3.308,0,1,0,.042-6.6Zm0,0" transform="translate(0 0.668)" fill={color} />
        <path id="Path_3571" data-name="Path 3571" d="M8.109,198.313h6.344V217.4H8.109Zm0,0" transform="translate(-7.734 -189.112)" fill={color}/>
        <path id="Path_3572" data-name="Path 3572" d="M233.277,188.625c-3.422,0-5.717,3.216-5.717,3.216v-2.768h-6.344V208.16h6.344V197.5a4.347,4.347,0,0,1,.209-1.548,3.473,3.473,0,0,1,3.255-2.32c2.3,0,3.214,1.75,3.214,4.316V208.16h6.344V197.216c0-5.863-3.13-8.591-7.3-8.591Zm0,0" transform="translate(-210.985 -179.872)" fill={color}/>
      </g>
    </svg>
  )
}

export default LinkedIn;