
export default function VehicleArrow({ fill = "#1A1C1F"}: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6.356" height="11.15" viewBox="0 0 6.356 11.15">
      <g id="arrow-down-sign-to-navigate_1_" data-name="arrow-down-sign-to-navigate (1)" transform="translate(6.356) rotate(90)">
        <path id="Path_3538" data-name="Path 3538" d="M5.575,0a.779.779,0,0,0-.552.229L.229,5.023a.781.781,0,0,0,1.1,1.1L5.575,1.885,9.817,6.127a.781.781,0,0,0,1.1-1.1L6.127.228A.779.779,0,0,0,5.575,0Z" transform="translate(0 0)" fill={fill}/>
      </g>
    </svg>
  )
}


