import { useEffect, useState } from "react";

// Takes the HTML element reference and returns a boolean
// that is true when the user is almost scrolled to element.
// Best for loading data on scroll.

const useScrolledTo = (elemRef: React.RefObject<HTMLDivElement>, precision = 300, fromBottom = true) => {
  const [scrolledTo, setScrolledTo] = useState(false);

  useEffect(() => {
    if (!scrolledTo) {
      const fetchOnScrolledTo = () => {
        if (elemRef?.current) {
          let top = elemRef.current.getBoundingClientRect().top;
        
          // above the component
          if (top > 0) {
            top -= window.innerHeight;
            
            // about to scroll the component
            if (top <= precision) {
              setScrolledTo(true);
            }
          }
          else if (fromBottom) {
            const bottom = elemRef.current.getBoundingClientRect().bottom + window.innerHeight;

            // about to scroll the component
            if (bottom >= 150) {
              setScrolledTo(true);
            }
          }

        }
      }

      window.addEventListener('scroll', fetchOnScrolledTo);
      return () => window.removeEventListener('scroll', fetchOnScrolledTo);
    }
  }, [elemRef, scrolledTo, precision, fromBottom])

  return scrolledTo;
}

export default useScrolledTo;