/* eslint-disable @next/next/no-img-element */
import React, { useRef } from 'react';
import Link from 'next/link'

import useMobile from '../Hooks/Mobile';
import { event } from '../../lib/gtag';

import SideMenuNav from '../SideMenuNav';
import Newsletter from '../Newsletter';
import SocialMedia from '../SocialMedia';

import styles from './styles.module.scss';
import NevoLogo from '../../public/assets/images/NevoLogo';
import CloseIcon from '../../public/assets/icons/close.svg'
import { copyrightDisplayText } from "../../helper/functions/copyrightDisplayText";


interface iSideMenu {
  hideMenu: any;
}

const SideMenu = ({ hideMenu }: iSideMenu) => {
  const mobile = useMobile(567);
  const refMenu = useRef<HTMLDivElement>(null)
  
  const onHideMenu = () => {
    if (refMenu && refMenu.current) {
      refMenu.current.classList.toggle(styles.sideMenuHide);
    }

    setTimeout(() => {
      if (refMenu && refMenu.current) {
        refMenu.current.style.display = "none";
      }

      const htmlDoc = document.children[0] as HTMLElement;   

      if (htmlDoc.style) {
        htmlDoc.style.overflow = "";
        document.body.style.overflow = "";
      }
      hideMenu();
    }, 475);
  }

  const renderSideMenuHeader = () => {
    return (
      <div className={styles.sideMenuHeader}>
        { 
          mobile && 
          <Link href='/' passHref>
            <div className={`${styles.nevoLogo} nevo-logo`}>
              <NevoLogo color="#1A1C1F" /> 
            </div>
          </Link>
        }
        
        <div onClick={onHideMenu} className={styles.closeIconContainer}>
          <img src={CloseIcon.src} alt="close icon" />
        </div>
        
      </div>
    )
  }
  
  return (
    <>
      <div ref={refMenu} className={styles.sideMenu}>
        <div className={styles.sideMenuContent}>
          
          { renderSideMenuHeader() }
          
          <div className={styles.menuContent}>
            <SideMenuNav hideMenu={onHideMenu} renderSideMenuHeader={renderSideMenuHeader} /> 
            
            <p className={`${styles.nevoChargingButton} nav-button`}>
              <a onClick={() => event("nevoAdvisorySideMenu")} href='https://nevoadvisory.ie' target='_blank' rel='noreferrer'>
                Nevo Advisory
              </a>
            </p>

            <p className="nav-button" 
              onClick={() => {
                event("ourMissionSideMenu")
                onHideMenu();
              }}
            >
              <Link href="/our-mission">Our Mission</Link>
            </p>

            <p className="nav-button" 
              onClick={() => {
                event("partnersSideMenu")
                onHideMenu();
              }}
            >
              <Link href="/partners">Partners</Link>
            </p>

            <p className="nav-button" 
              onClick={() => {
                event("contactUsSideMenu")
                onHideMenu();
              }}
            >
              <Link href="/contact-us" passHref>Contact Us</Link>
            </p>

            {/* <p className="nav-button" 
              onClick={() => {
                event("feedbackSideMenu")
                onHideMenu();
              }}
            >
              <Link href="/feedback" passHref>Feedback</Link>
            </p> */}
          </div>

          <div className={styles.bottomContent}>
            <Newsletter />
            
            <div className={styles.socialMedia}>
              <SocialMedia />
            </div>

            <div className={styles.copyright}>
              <p className="copyright-mark">{copyrightDisplayText()}</p>
            </div>
          </div>
        </div>
      </div>
      
      <div onClick={onHideMenu} className={"fading-cover " + styles.fadingCover}>&nbsp;</div>
    </>
    
  )
}

export default SideMenu;