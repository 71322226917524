import React from 'react';

interface ISvgIcon {
  color: string;
}

const Instagram = ({color = "#8d949d"} : ISvgIcon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28.532" height="28.538" viewBox="0 0 28.532 28.538">
      <g id="instagram" transform="translate(0.125 0.125)">
        <path id="Path_3567" data-name="Path 3567" d="M28.659,8.317A10.337,10.337,0,0,0,28,4.885,7.246,7.246,0,0,0,23.862.746,10.363,10.363,0,0,0,20.431.089C18.917.017,18.436,0,14.6,0s-4.321.017-5.83.083A10.339,10.339,0,0,0,5.334.74,6.9,6.9,0,0,0,2.825,2.376a6.965,6.965,0,0,0-1.63,2.5A10.364,10.364,0,0,0,.537,8.311c-.072,1.514-.089,2-.089,5.836s.017,4.321.083,5.83a10.336,10.336,0,0,0,.658,3.432,7.245,7.245,0,0,0,4.139,4.139,10.364,10.364,0,0,0,3.432.658c1.508.066,1.989.083,5.83.083s4.321-.016,5.83-.083a10.334,10.334,0,0,0,3.432-.658,7.236,7.236,0,0,0,4.139-4.139,10.371,10.371,0,0,0,.658-3.432c.066-1.509.083-1.989.083-5.83s-.006-4.321-.072-5.83Zm-2.547,11.55a7.754,7.754,0,0,1-.486,2.625,4.692,4.692,0,0,1-2.686,2.686,7.781,7.781,0,0,1-2.625.486c-1.492.066-1.94.083-5.714.083s-4.227-.016-5.714-.083a7.749,7.749,0,0,1-2.625-.486,4.353,4.353,0,0,1-1.625-1.056A4.4,4.4,0,0,1,3.582,22.5,7.782,7.782,0,0,1,3.1,19.872c-.066-1.492-.083-1.94-.083-5.714S3.029,9.93,3.1,8.444a7.75,7.75,0,0,1,.486-2.625A4.3,4.3,0,0,1,4.643,4.194,4.391,4.391,0,0,1,6.268,3.139a7.786,7.786,0,0,1,2.625-.486c1.492-.066,1.94-.083,5.714-.083s4.227.017,5.714.083a7.753,7.753,0,0,1,2.625.486A4.35,4.35,0,0,1,24.57,4.194a4.4,4.4,0,0,1,1.056,1.625,7.785,7.785,0,0,1,.486,2.625c.066,1.492.083,1.94.083,5.714s-.017,4.216-.083,5.709Zm0,0" transform="translate(-0.449 0)" fill={color} stroke={color} strokeWidth="0.25"/>
        <path id="Path_3568" data-name="Path 3568" d="M132.216,124.5a7.267,7.267,0,1,0,7.267,7.267A7.269,7.269,0,0,0,132.216,124.5Zm0,11.981a4.714,4.714,0,1,1,4.714-4.714A4.715,4.715,0,0,1,132.216,136.481Zm0,0" transform="translate(-118.069 -117.62)" fill={color} stroke={color} strokeWidth="0.25"/>
        <path id="Path_3569" data-name="Path 3569" d="M365.843,90.3a1.7,1.7,0,1,1-1.7-1.7A1.7,1.7,0,0,1,365.843,90.3Zm0,0" transform="translate(-342.446 -83.706)" fill={color} stroke={color} strokeWidth="0.25"/>
      </g>
    </svg>
  )
}

export default Instagram;