import React, { useEffect, useMemo, useRef, useState } from 'react';
import Link from 'next/link';

import { incrementValueFromDate } from '../../../helper/increase_per_second';
import useIncreaseBy from '../../Hooks/IncreaseBy';
import { event } from '../../../lib/gtag';

import StatNumber from '../../StatNumber';

import styles from './styles.module.scss'

const BEV_PHEV_DATE = new Date(2022, 5, 14); // 2022-06-14
const KM_CARBON_DATE = new Date(2022, 6, 21); // 2022-07-21

const GOVERMENT_TARGET = 945_000; // by 2030 EVs on road

// state for 14.06.2022 00:00:00
const BEV_PHEV = 48_042; // increase by 0.001 per second

// state for 21.07.2022 00:00:00
const TONS_OF_CARBON = 87_497; // increase by 0.002 per second
const TRAVELLED_KM = 506_858_494; // increase by 16 per second

export default function EVStatistics()  {
  const [kmTravelled, setKmTravelled] = useState(0)
  const [bevPhEV, setBevPhEV] = useState(0)
  const [carbon, setCarbon] = useState(0)

  const [animateStats, setAnimateStats] = useState(false)
  const dataHolderRef = useRef<HTMLDivElement>(null)
  
  // calculate values
  useEffect(() => {
    setKmTravelled(incrementValueFromDate(TRAVELLED_KM, 16, KM_CARBON_DATE))
    setBevPhEV(incrementValueFromDate(BEV_PHEV, 0.001, BEV_PHEV_DATE))
    setCarbon(incrementValueFromDate(TONS_OF_CARBON, 0.002, KM_CARBON_DATE))
  }, [])

  // Appear animation for Carbon and Weeks
  useEffect(() => {
    const scrollAnimation = () => {
      if (dataHolderRef?.current) {
        const position = dataHolderRef.current.getBoundingClientRect().top - window.innerHeight;
      
        if (position < -50) {
          setAnimateStats(true) // so the number can appear and start animation
          window.removeEventListener('scroll', scrollAnimation);
        }
      }
    }

    scrollAnimation();

    window.addEventListener('scroll', scrollAnimation);
    return () => window.removeEventListener('scroll', scrollAnimation);
  }, []);
  
  // and rounding number to 1 decimal
  const targetPercent = useMemo(() => {
    return Math.round((bevPhEV / GOVERMENT_TARGET) * 1000) / 10 
  }, [bevPhEV])

  const kmIncreasing = useIncreaseBy(kmTravelled, 16)

  return (
    <div className={styles.evStatistics}>
      <div className={styles.backgroundImageHolder}>
        <div className={styles.greyPart}>
          <h1>Ireland&apos;s EV stats</h1>

          <div className={styles.dataHolder} ref={dataHolderRef}>
            <StatNumber 
              number={bevPhEV}
              text="BEV & PHEV on the road"
              animate={animateStats}
              format
            />

            <StatNumber 
              number={targetPercent}
              text="Of Government EV goal by 2030"
              animate={animateStats}
              unit="%"
            />

            <StatNumber 
              number={carbon}
              text="Tonnes of Carbon saved"
              animate={animateStats}
              format
            />
          </div>
        </div>      
      </div>


      <div className={styles.whitePart}>
        <div className={styles.dataHolder}>
          <StatNumber 
            number={kmIncreasing}
            text="Electric KMs travelled to date in Ireland"
            animate={false} countUp={false} format
            className={styles.kmNumber}
          />

          <Link href='/learn/articles' passHref>
            <button className={`${styles.measureButton} blue-button`} onClick={() => {event("homeMeasureActionClick")}}>
              Learn More About EVs
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
};
