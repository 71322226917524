/* eslint-disable @next/next/no-img-element */
import React from "react";

import { components } from "react-select";

import CrossIcon from '../../public/assets/icons/close.svg';

const { Option } = components;

const IconOption = (props: any) => (
  <Option {...props}>
    {props.data.label}
    { props.isSelected && (
      <img
        src={CrossIcon.src}
        style={{ width: 10 }}
        alt={props.data.label}
      />
    )}
  </Option>
);

export default IconOption;