
import axios from "axios";

export default axios.create({
  baseURL: process.env.NEXT_PUBLIC_SWEEP_BASE_API,
  headers: {
    'Content-Type': 'application/json',
    "Access-Control-Expose-Headers" : "Access-Control-*",
    "Access-Control-Allow-Headers" : "Access-Control-*, Origin, X-Requested-With, Content-Type, Accept",
    'Access-Control-Allow-Methods' : 'GET, POST, PUT, DELETE, OPTIONS, HEAD',
    'Access-Control-Allow-Origin' : '*'
  }
});

