import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';

import styles from './styles.module.scss'
import ArrowIcon from '../../public/assets/icons/right-arrow.svg'

interface IExtendingPanels {
  panel: number;
  setPanel: (panel: number) => void;
  children: JSX.Element[];
  className?: string;
  controlsClassName?: string;
}

export default function SwipingPanels({ panel, setPanel, children, className, controlsClassName }: IExtendingPanels)  {
  const [width, setWidth] = useState(0);
  const [startScroll, setStartScroll] = useState(0);

  const sweeperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    }

    handleResize()

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  // perform swiping animation
  useEffect(() => {
    const scrollTo = panel * width;

    sweeperRef?.current?.scrollTo({
      left: scrollTo,
      behavior: 'smooth'
    })
  }, [panel, width])

  const onTouchStart = () => {
    if (sweeperRef?.current) {
      setStartScroll(sweeperRef.current.scrollLeft);
    }
  }
  const onTouchEnd = () => {
    if (sweeperRef?.current) {
      const endScroll = sweeperRef.current.scrollLeft;

      // swipe right
      if (startScroll < endScroll && !(endScroll > width * 2)) {
        nextPanel()
      }
      // swipe left
      else if (startScroll > endScroll && !(endScroll < 0)) {
        prevPanel()
      }
    }
  }

  const prevPanel = () => {
    if (panel <= 0) return; 

    if (sweeperRef.current) {
      const prevSlide = Math.round(sweeperRef.current?.scrollLeft / width) - 1;
      if (prevSlide < 0) {
        setPanel(0);
        return
      }

      setPanel(prevSlide);
    }
  }
  const nextPanel = () => {
    if (panel >= children.length - 1) return;

    if (sweeperRef.current) {
      const nextSlide = Math.round(sweeperRef.current?.scrollLeft / width) + 1;
      if (nextSlide > children.length - 1) { 
        setPanel(children.length - 1);
        return; 
      }

      setPanel(nextSlide);
    }
  }

  const setNextPanel = () => {
    let nextPanel = panel + 1
    setPanel(nextPanel >= children.length ? 0 : nextPanel);
  }
  const setPrevPanel = () => {
    let prevPanel = panel - 1;
    setPanel(prevPanel < 0 ? children.length - 1 : prevPanel);
  }

  return (
    <div className={`${styles.homeWrapper} ${className}`}>
      <div className={`${styles.controls} ${controlsClassName}`}>
        <div className={styles.arrowHolder} onClick={setPrevPanel}>
          <Image layout='responsive' src={ArrowIcon} alt="left arrow"/>
        </div>
        <div className={styles.arrowHolder} onClick={setNextPanel}>
          <Image layout='responsive' src={ArrowIcon} alt="left arrow"/>
        </div>
      </div>

      <div 
        className={styles.sweeperWrapper} 
        ref={sweeperRef} 
        onTouchStart={onTouchStart}
        onTouchEnd={onTouchEnd}
      >
        { children.map((item, index) => (
          <div className={styles.slide} key={index}>
            {item}
          </div>
        ))}
      </div>
    </div>
  )
};
