import { useEffect } from 'react';
import { Canceler } from 'axios';

/**
 * Cancel Request Hook 
 * @description Cancels the request if the component unmounts
 * @param cancel: if defined, means the request is already called and might be cancelled 
 */

const useCancel = (cancel: Canceler | undefined) => {
  useEffect(() => {
    if (cancel) {
      return cancel;
    }
  }, [cancel])
}

export default useCancel;
  