import iFilters from "../interfaces/iFilters";
import IOption from "../interfaces/IOption";
import { privateBodies, commercialBodies } from "./filters";

// Lists of availaible filters options: 
export const videoSortOptions : IOption[] = [
  { value: "", label: "Featured" },
  { value: "newest", label: "Most Recent" },
  { value: "views", label: "Most Viewed" },
  { value: "a-z", label: "Alphabetical" },
  { value: "oldest", label: "Oldest" },
]

export const videoCategories : IOption[] = [
  { value: "", label: "All" },
  { value: 'electric-cars', label: "Electric Cars" },
  { value: 'electric-vans', label: "Electric Vans" },
  { value: 'chargers', label: "Chargers" },
  { value: 'motor-shows', label: "Motor Shows" },
]

export const categoriesSubtitutes: any = {
  'electric-cars': 1,
  'electric-vans': 81,
  'chargers': 82,
  'motor-shows': 83,
}

export const bodyTypeTags : IOption[] = [
  { value: "", label: "All" },
  ...privateBodies,
  ...commercialBodies,
  { value: "PICK UP", label: "Pick Up" },
]

const videoFilters : iFilters = {
  order_by: videoSortOptions[0],
  category: videoCategories[0],
  body_type: bodyTypeTags[0],
}

export const queryParamKeys = [
  

]

export default videoFilters;