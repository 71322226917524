/* eslint-disable react/no-unknown-property */
import React from 'react';

interface INevoLogo {
  color: string;
}

const NevoLogo = ({color} : INevoLogo) => {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 83 39">
      <g>
        <path fill={color} d="M12,23.2L3.5,11.62l-3,0.01L12,27.39l0,0l0,0h1.13c0.72,0,1.31-0.58,1.31-1.31V11.61H12V23.2z"/>
        <polygon fill={color} points="0.5,27.39 2.94,27.39 2.94,22.06 0.5,18.71 	"/>
        <path fill={color} d="M34.7,11.62h-9.79c-0.72,0-1.31,0.58-1.31,1.31v1.17h12.41v-1.17C36.01,12.2,35.42,11.62,34.7,11.62z"/>
        <path fill={color} d="M23.6,26.09c0,0.72,0.58,1.31,1.31,1.31h9.79c0.72,0,1.31-0.58,1.31-1.31v-1.17H23.6V26.09z"/>
        <rect x="23.6" y="18.27" fill={color} width="12.41" height="2.47"/>
        <path fill={color} d="M47.45,12.43c-0.2-0.5-0.68-0.82-1.21-0.82H44.5l5.99,14.96c0.2,0.49,0.67,0.82,1.21,0.82l1.75,0.01
          L47.45,12.43z"/>
        <polygon fill={color} points="54.39,18.48 55.7,21.75 59.78,11.61 57.15,11.61 	"/>
        <path fill={color} d="M76.56,11.62v2.61c2.17,0.89,3.66,3.11,3.43,5.65c-0.24,2.67-2.42,4.81-5.1,5.02
          c-3.25,0.24-5.97-2.33-5.97-5.53c0-2.32,1.43-4.31,3.45-5.13v-2.61c-3.54,0.95-6.11,4.27-5.93,8.15c0.2,4.1,3.53,7.43,7.64,7.62
          c4.61,0.21,8.41-3.46,8.41-8.02C82.5,15.66,79.98,12.54,76.56,11.62z"/>
      </g>
    </svg>
  )
}

export default NevoLogo;