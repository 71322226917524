import React from 'react';

interface ISvgIcon {
  color: string;
}

const TikTok = ({color = "#8d949d"} : ISvgIcon) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24.258" height="28.288" viewBox="0 0 24.258 28.288">
      <path id="tiktok" d="M18.247,0c.467,4.014,2.707,6.407,6.6,6.662v4.515c-2.257.221-4.235-.518-6.535-1.91v8.444c0,10.727-11.695,14.079-16.4,6.39C-1.1,19.154.748,10.473,10.44,10.125v4.761a14.023,14.023,0,0,0-2.249.552c-2.156.73-3.378,2.1-3.038,4.506.653,4.617,9.123,5.983,8.419-3.038V.008h4.676Z" transform="translate(-0.591)" fill={color}/>
    </svg>
  )
}

export default TikTok;