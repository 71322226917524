import React from 'react'
import Link from 'next/link';

import { vehicleSearchOptions } from '../../helper/constants'
import { event } from '../../lib/gtag';

import styles from './styles.module.scss'

interface VehicleTypesSelectorProps {
  className?: string;
}

export default function VehicleTypesSelector({ className }: VehicleTypesSelectorProps) {
  return (
    <div className={`${styles.vehicleTypes} ${className}`}>
      {vehicleSearchOptions.map((option, index) => (
        <Link key={index} href={option.link} passHref>
          <p className={`${styles.typeBtn} ${option.blue && styles.blueButton} nav-button`} onClick={() => event(option.event)}>
            { option.label }
          </p>
        </Link>
      ))}
    </div>
  )
}