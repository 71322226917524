import TagManager from "react-gtm-module";
import isQAorDev from "../utils/DevelopmentMode";

export const GA_TRACKING_ID = "GTM-5M5T6WP";

export const tagManagerArgs = {
	gtmId: GA_TRACKING_ID,
	events: {
		// home page events
		homeVehicleSearchClick: "Home Vehicle Search Click",
		homePlanYourJourneyClick: "Home Plan Your Journey Click",
		homeMeasureActionClick: "Home Measure Action Click",
		homeAskNevoSeeMoreClick: "Home #AskNevo See More Click",

		// journeys pages events
		planJourneyClick: "Plan Journey Click",
		shareJourneyClick: "Share Journey Click",

		subscribedToNewsletter: "Subscribed to Newsletter",

		clickedOnLearnMore: "Learn More Clicks",
		clickedOnEmail: "hello@nevo.co Clicks",
		clickedOnFacebook: "Facebook Clicks",
		clickedOnInstagram: "Instagram Clicks",
		clickedOnTikTok: "TikTok Clicks",
		clickedOnTwitter: "Twitter Clicks",
		clickedOnLinkedin: "Linkedin Clicks",
		clickedOnYouTube: "YouTube Clicks",

		vehiclesHeader: "Vehicles on Header",
		vehiclesFooter: "Vehicles on Footer",
		vehiclesSideMenu: "Vehicles on Side Menu",

		compareEvFooter: "Compare Ev on Footer",
		compareEvHeader: "Compare Ev on Header",
		compareEvSideMenu: "Compare Ev on Side Menu",

		financeFooter: "Finance on Footer",
		financeHeader: "Finance on Header",
		financeSideMenu: "Finance on Side Menu",

		electricCarFooter: "Electric Car on Footer",
		electricCarHeader: "Electric Car on Header",
		electricCarSideMenu: "Electric Car on Side Menu",

		plugInHybridsFooter: "Plug-In Hybrids on Footer",
		plugInHybridsHeader: "Plug-In Hybrids on Header",
		plugInHybridsSideMenu: "Plug-In Hybrids on Side Menu",

		electricVansFooter: "Electric Vans on Footer",
		electricVansHeader: "Electric Vans on Header",
		electricVansSideMenu: "Electric Vans on Side Menu",

		helpFooter: "Help on Footer",
		helpHeader: "Help on Header",
		helpSideMenu: "Help on Side Menu",

		servicesFooter: "Services on Footer",
		servicesHeader: "Services on Header",
		servicesSideMenu: "Services on Side Menu",

		newsFeedFooter: "News Feed on Footer",
		newsFeedHeader: "News Feed on Header",
		newsFeedSideMenu: "News Feed on Side Menu",

		articlesFooter: "Articles on Footer",
		articlesHeader: "Articles on Header",
		articlesSideMenu: "Articles on Side Menu",

		videoFooter: "Video on Footer",
		videoHeader: "Video on Header",
		videoSideMenu: "Video on Side Menu",

		askNevoFooter: "Ask Nevo on Footer",
		askNevoHeader: "Ask Nevo on Header",
		askNevoSideMenu: "Ask Nevo on Side Menu",

		journeysFooter: "Journeys on Footer",
		journeysHeader: "Journeys on Header",
		journeysSideMenu: "Journeys on Side Menu",

		journeyPlannerFooter: "Journey Planner on Footer",
		journeyPlannerHeader: "Journey Planner on Header",
		journeyPlannerSideMenu: "Journey Planner on Side Menu",

		hotelsFooter: "Hotels on Footer",
		hotelsHeader: "Hotels on Header",
		hotelsSideMenu: "Hotels on Side Menu",

		ourPartnersFooter: "Our Partners on Footer",
		ourPartnersHeader: "Our Partners on Header",
		ourPartnersSideMenu: "Our Partners on Side Menu",

		nevoAdvisoryFooter: "Nevo Advisory on Footer",
		nevoAdvisoryHeader: "Nevo Advisory on Header",
		nevoAdvisorySideMenu: "Nevo Advisory on Side Menu",

		electricVehicleShowFooter: "Electric Vehicle Show Footer",
		electricVehicleShowHeader: "Electric Vehicle Show Header",
		electricVehicleShowSideMenu: "Electric Vehicle Show Side Menu",

		ourMissionFooter: "Our Mission Footer",
		ourMissionSideMenu: "Our Mission Side Menu",

		contactUsFooter: "Contact Us Footer",
		contactUsSideMenu: "Contact Us Side Menu",

		insuranceFooter: "Insurance Footer",
		insuranceSideMenu: "Insurance Side Menu",
		insuranceHeader: "Insurance Header",
		axaPartnerPageGetAQuoteTopClick: "Axa Partner Page Get A Quote Top Click",
		axaPartnerPageGetAQuoteBottomClick: "Axa Partner Page Get A Quote Bottom Click",
		axaPartnerPageFindOutMoreClick: "Axa Partner Page Find Out More Click",
		axaPartnerPagePhoneClick: "Axa Partner Page Phone Click",
		axaPartnerPageBranchClick: "Axa Partner Page Branch Click",
		axaPartnerPageFacebookClick: "Axa Partner Page Facebook Click",

		termsFooter: "Terms Footer",
		privacyFooter: "Privacy Footer",
		cookiesFooter: "Cookies Footer",
		vehicleSearch: "Vehicle Search",
		testDriveEnquire: "Test Drive Enquire",

		openVideoClick: "Click to Open Video",
		clickBookNow: "Hotel book now click",
		clickOnHotel: "Hotel click",

		// Lease Events
		leaseVehicleClick: "Lease Vehicle Click",
		leaseVehicleCardClick: "Lease Vehicle Card Click",
		leaseVehicleOnLeasingClick: "Lease Vehicle On Leasing Click",
		leaseVehicleCardOnLeasingClick: "Lease Vehicle Card On Leasing Click",

		// Vehicle Enquiry Events
		vehicleEnquiryQuestion: "Vehicle Enquiry Question form submitted",
		vehicleEnquiryCallDealership: "Vehicle Enquiry Call Dealership form submitted",
		vehicleEnquiryRequestCallback: "Vehicle Enquiry Request Callback form submitted",
		vehicleEnquiryScheduleTestDrive: "Vehicle Enquiry Schedule Test Drive form submitted",

		// Linders Events
		lindersFormSubmitted: "Linders Form Submitted",
		lindersCardClicked: "Linders Card Clicked",
		lindersBannerViewed: "Linders Banner Viewed",

		// Vehicle Popup Events
		popupGoElectricViewed: "Popup Go Electric Viewed",
		popupGoElectricClicked: "Popup Go Electric Clicked",

		popupTryThisEVViewed: "Popup Try This EV Viewed",
		popupTryThisEVClicked: "Popup Try This EV Clicked",

		popupBookTestDriveViewed: "Popup Book Test Drive Viewed",
		popupBookTestDriveClicked: "Popup Book Test Drive Clicked",

		// Vehicle Actions
		actionBuildClick: "Action Build Click",
		actionBrochureClick: "Action Brochure Click",
		actionReviewClick: "Action Review Click",
		actionCompareClick: "Action Compare Click",
		actionShareClick: "Action Share Click",

		clickedOnHamburgerMenu: "Clicked on Hamburger Menu",

		// Filter actions
		clickedOnFuelTypeDropdown: "Clicked on Fuel Type Dropdown",
		clickedOnMakeDropdown: "Clicked on Make Dropdown",
		clickedOnModelDropdown: "Clicked on Model Dropdown",
		clickedOnBodyTypeDropdown: "Clicked on Body Type Dropdown",
		clickedOnMinPriceDropdown: "Clicked on Min Price Dropdown",
		clickedOnMaxPriceDropdown: "Clicked on Max Price Dropdown",
		clickedOnMinRangeDropdown: "Clicked on Min Range Dropdown",
		clickedOnMaxRangeDropdown: "Clicked on Max Range Dropdown",
		clickedOnSearchButton: "Clicked on Search Button",
		clickedOnResetButton: "Clicked on Reset Button",

		// Commercial Filters
		clickedOnMinLoadCapaticyDropdown: "Clicked on Min Load Capaticy Dropdown",
		clickedOnMaxLoadCapaticyDropdown: "Clicked on Max Load Capaticy Dropdown",
		clickedOnMinLoadWeightDropdown: "Clicked on Min Load Weight Dropdown",
		clickedOnMaxLoadWeightDropdown: "Clicked on Max Load Weight Dropdown",
		clickedOnMinTowingWeightDropdown: "Clicked on Min Towing Weight Dropdown",
		clickedOnMaxTowingWeightDropdown: "Clicked on Max Towing Weight Dropdown",

		// Used EVs Filters
		clickedOnColorDropdown: "Clicked on Color Dropdown",
		clickedOnMinYearDropdown: "Clicked on Min Year Dropdown",
		clickedOnMaxYearDropdown: "Clicked on Max Year Dropdown",
		clickedOnMinMileageDropdown: "Clicked on Min Mileage Dropdown",
		clickedOnMaxMileageDropdown: "Clicked on Max Mileage Dropdown",
		clickedOnLocationDropdown: "Clicked on Location Dropdown",

		// Lease Filters
		clickedOnLeasingTypeDropdown: "Clicked on Leasing Type Dropdown",
		clickedOnMinPricePerMonthDropdown: "Clicked on Min Price Per Month Dropdown",
		clickedOnMaxPricePerMonthDropdown: "Clicked on Max Price Per Month Dropdown",

		// Finance Events
		financeVehicleClick: "Finance Vehicle Click",
		financeVehicleOnFinanceClick: "Finance Vehicle On Finance Click",

		// Partners Page
		partnersSideMenu: "Partners on Side Menu",
		clickedOnPartnerCard: "Clicked on Partner Card",

		// Home Vehicle Panel Events
		homeHatchbackClick: "Home Hatchback Click",
		homeSaloonClick: "Home Saloon Click",
		homeEstateClick: "Home Estate Click",
		homeSUVClick: "Home SUV Click",
		homeMPVClick: "Home MPV Click",

		homeNewVehiclesClick: "Home New Vehicles Click",
		homeLeasingClick: "Home Leasing Click",
		homeFinanceClick: "Home Finance Click",
		homeUsedVehiclesClick: "Home Used Vehicles Click",
		homeCommercialVehiclesClick: "Home Commercial Vehicles Click",

		homeSearchClick: "Home Search Click",
		homeMakesClick: "Home Makes Click",
		homeModelsClick: "Home Models Click",

		homePopularAllElectricClick: "Home Popular All Electric Click",
		homePopularPlugInClick: "Home Popular Plug In Click",
		homePopularSUVClick: "Home Popular SUV Click",
		homePopularUnder40kClick: "Home Popular Under 40k Click",

		// Keep Browsing Component Events
		keepBrowsingAllElectricClick: "Keep Browsing All Electric Click",
		keepBrowsingHybridClick: "Keep Browsing Hybrid Click",
		keepBrowsingUsedEVsClick: "Keep Browsing Used EVs Click",

		// Video Search Events
		videoSearch: "Video Search",
		videoSearchPageDropdownContentTypeClick: "Video Search Page Dropdown Content Type Click",
		videoSearchPageDropdownSortByClick: "Video Search Page Dropdown Sort By Click",
		videoSearchPageDropdownMakeClick: "Video Search Page Dropdown Make Click",
		videoSearchPageDropdownModelClick: "Video Search Page Dropdown Model Click",
		videoSearchPageDropdownBodyTypeClick: "Video Search Page Dropdown Body Type Click",

		// Video Page Events
		videoPagePlayVideoClick: "Video Page Play Video Click",

		// Electric Vehicle Show Events
		evShowBlueTopBannerRegisterClick: "EV Show Blue Top Banner Register Click",
		evShowPageRegisterTopBannerClick: "EV Show Page Register Top Banner Click",
		evShowPageRegisterWhatToExpectClick: "EV Show Page Register What To Expect Click",
		evShowPageRegisterBottomClick: "EV Show Page Register Bottom Click",
		evShowPageBankOfIrelandClick: "EV Show Page Bank Of Ireland Click",
		evShowPageAXAClick: "EV Show Page AXA Click",
		evShowPageViewAllVehiclesClick: "EV Show Page View All Vehicles Click",
		evShowPageViewEVReviewsClick: "EV Show Page View EV Reviews Click",
		evShowPageNevoWebsiteClick: "EV Show Page Nevo Website Click",
		evShowPageMgClick: "EV Show Page Mg Click",
		evShowPageDsClick: "EV Show Page Ds Click",
		evShowPageTeslaClick: "EV Show Page Tesla Click",
		evShowPageBydClick: "EV Show Page Byd Click",
		evShowPagePorscheClick: "EV Show Page Porsche Click",
		evShowPageCitroenClick: "EV Show Page Citroen Click",
		evShowPageJeepClick: "EV Show Page Jeep Click",
		evShowPageLotusClick: "EV Show Page Lotus Click",
		evShowPagePeugeotClick: "EV Show Page Peugeot Click",
		evShowPageHyundaiClick: "EV Show Page Hyundai Click",
		evShowPageVolvoClick: "EV Show Page Volvo Click",
		evShowPageMaxusClick: "EV Show Page Maxus Click",
		evShowPageOpelClick: "EV Show Page Opel Click",
		evShowPageFiatClick: "EV Show Page Fiat Click",
		evShowPageHondaClick: "EV Show Page Honda Click",
		evShowPageSmartClick: "EV Show Page Smart Click",
		evShowPageKiaClick: "EV Show Page Kia Click",
		evShowPageAudiClick: "EV Show Page Audi Click",
		evShowPageAlfaromeoClick: "EV Show Page Alfa Romeo Click",
		evShowPageCupraClick: "EV Show Page Cupra Click",
		evShowPageFordClick: "EV Show Page Ford Click",
		evShowPageLexusClick: "EV Show Page Lexus Click",
		evShowPageSubaruClick: "EV Show Page Subaru Click",
		evShowPageOraClick: "EV Show Page Ora Click",
		evShowPageNissanClick: "EV Show Page Nissan Click",
		evShowPageRenoClick: "EV Show Page Reno Click",
		evShowPageToyotaClick: "EV Show Page Toyota Click",
		evShowPageMitshubishiClick: "EV Show Page Mitshubishi Click",
		evShowPageVolkswagenClick: "EV Show Page Volkswagen Click",
		evShowPageTradebidClick: "EV Show Page Tradebid Click",
		evShowPageInstabidClick: "EV Show Page Instabid Click",
		homeElectricVehicleShowClick: "Home Electric Vehicle Show Click",

		evShowPopupViewedHomePage: "EV Show Popup Viewed Home Page",
		evShowPopupClickedHomePage: "EV Show Popup Clicked Home Page",
		evShowPopupClosedHomePage: "EV Show Popup Closed Home Page",

		clickedOnEnquireVehicleButtonEvComparisonPage: "Clicked on Enquire Vehicle Button EV Comparison Page",
		clickedOnEnquireLeaseButtonEvComparisonPage: "Clicked on Enquire Lease Button EV Comparison Page",

		bestDrivePageVisitWebsiteClick: "BestDrive Page Visit Website Click",
		bestDrivePageBookServiceClick: "BestDrive Page Book Service Click",
		bestDrivePageGetQuoteClick: "BestDrive Page Get Quote Click"
	}
};

export const vehicleEnquiryDict: any = {
	QUESTION: "vehicleEnquiryQuestion",
	CALL_DEALERSHIP: "vehicleEnquiryCallDealership",
	REQUEST_CALLBACK: "vehicleEnquiryRequestCallback",
	SCHEDULE_TEST_DRIVE: "vehicleEnquiryScheduleTestDrive"
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
	if (window.gtag && !isQAorDev()) {
		window.gtag("config", GA_TRACKING_ID, {
			page_path: url
		});
	}
};

export const event = (event: string) => {
	const qaDevMode = isQAorDev();

	if (qaDevMode) console.log(`Event occured: {${event}}`);

	if (!Cookiebot.consent.statistics || qaDevMode) return;

	try {
		TagManager.dataLayer({
			dataLayer: {
				event: event
			}
		});
	} catch (e) {
		console.error("Event. cookie doesn't work | incognito mode on");
	}
};

export const linkClick = (category: string, title: string, link: string) => {
	const qaDevMode = isQAorDev();

	if (qaDevMode) console.log("Link clicked:", category, title, link);

	if (!Cookiebot.consent.statistics || qaDevMode) return;

	try {
		TagManager.dataLayer({
			dataLayer: {
				insightCategory: category,
				insightTitle: title,
				insightLink: link
			}
		});
	} catch (e) {
		console.error("Event. cookie doesn't work | incognito mode on");
	}
};

export const dynamicEvent = (category: string, title: string) => {
	const qaDevMode = isQAorDev();

	if (qaDevMode) console.log("Dynamic event:", category, title);

	if (!Cookiebot.consent.statistics || qaDevMode) return;

	try {
		TagManager.dataLayer({
			dataLayer: {
				insightCategory: category,
				insightTitle: title
			}
		});
	} catch (e) {
		console.error("Event. cookie doesn't work | incognito mode on");
	}
};
