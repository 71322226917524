import { slugifyArray } from "../vehicle_url";

const generateQueryParams = (params: any) => {
  let queryParams = "?";

  for (let param in params) {
    if (Array.isArray(params[param]) && params[param].length !== 0) {
      queryParams += `${param}=${slugifyArray(params[param])}&`
    }
    else if (!!params[param]?.value) {
      queryParams += `${param}=${params[param].value}&`
    }
  }

  // removing last & sign
  if (queryParams.endsWith('&')) {
    queryParams = queryParams.substring(0, queryParams.length - 1)
  }

  return queryParams.length > 1 ? queryParams : ""
}

export default generateQueryParams