import { createContext, useMemo } from "react";
import { WebsiteType } from "../../helper/enums/website_types";

export const WebsiteTypeContext = createContext<any>(null);

export type WebsiteTypeContextType = {
	websiteType?: WebsiteType | null;
};

export default function WebsiteTypeStore({ children }: any) {
	const websiteType = useMemo(() => process.env.NEXT_PUBLIC_WEBSITE_TYPE as WebsiteType | undefined, []);

	return <WebsiteTypeContext.Provider value={{ websiteType }}>{children}</WebsiteTypeContext.Provider>;
}
