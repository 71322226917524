/* eslint-disable @next/next/no-img-element */

import React from 'react';

import SpinnerImg from '../../public/assets/icons/spinner.png';
import styles from './styles.module.scss'

const Spinner = () => {
  return (
    <img className={styles.spinner} src={SpinnerImg.src} alt="spinner"/>
  )
};

export default Spinner;