/* eslint-disable @next/next/no-img-element */
import React from 'react';
import Link from 'next/link';

import styles from './styles.module.scss';

interface IPostCard {
  name: string;
  description: string;
  url: string;
  thumbnail_image_url: string;
  trackEvent?: () => void;
}

const PostCard = ({
  name, description,
  url, thumbnail_image_url,
  trackEvent
} : IPostCard) => {
  const onRefClick = () => {
    if (trackEvent) trackEvent();
  }

  return (
    <Link href={url} passHref>
      <a className={styles.postCardHolder} onClick={onRefClick}>
        <div className={styles.postCard}>
          <div className={styles.squareImageHolder}>
            <img alt="text" src={`${thumbnail_image_url}`} />
          </div>
          <h1 className={styles.scrollHeader}>{ name }</h1>
          <p className={styles.scrollText}>{ description }</p>
        </div>
      </a>
    </Link>
  )
};

export default PostCard;
